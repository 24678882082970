import Testimonies from '../Components/Testimonies';

const Homepage = () => {
    return ( 
        <>
        <div>
            <div className="bg-gradient-to-t from-cyan-600 dark:bg-gradient-to-t dark:from-cyan-950 lg:h-[90vh] md:h-auto">
                <div className='flex flex-col mx-auto justify-center items-center md:pt-[150px] md:flex md:flex-row md:gap-4 md:container md:items-center md:justify-center md:mx-auto md:w-[80%] lg:container lg:mx-auto lg:w-[56%] lg:items-center lg:justify-center'>
                    <div className='w-[80%] pt-[100px] md:w-2/3 md:pt-0 lg:pt-[90px] xl:pt-[80px]'>
                        <h1 className='text-gray-900 font-bold text-5xl md:w-[80%] py-2'>DigiMoxy Solutions</h1>
                        <h2 className='text-cyan-600 font-bold text-4xl lg:py-2 md:w-[80%] dark:text-cyan-600'>Empowering Your Business with Cutting-Edge AI and Digital Solutions</h2>
                        <p className='text-lg font-light md:w-[80%] md:text-justify lg: md:pt-7'>Unlock the full potential of your business with our innovative AI-driven services. From #digitalmarketing strategies to seamless #webdevelopment and #virtualassistantservices, we provide comprehensive solutions tailored to your needs. Partner with DigiMoxy Solutions and stay ahead in the competitive market.</p>
                        <button className='bg-cyan-600 mx-auto text-gray-900 p-2 rounded-3xl font-semibold my-5 px-5 dark:bg-gradient-to-r from-purple-500 dark:text-white shadow-md hover:shadow-lg hover:shadow-gray-900 shadow-gray-900'><a href='/about'>Explore More..</a></button>
                    </div>
                    <div className='w-2/3 md:w-1/3'>
                        <img src='innovation.png' alt='hero-img' className=' h-[500px]'/>
                    </div>
                </div>
            </div>

            {/* why choose us section */}
            <section class="bg-white dark:bg-gray-900 md:pt-5 lg:mx-auto lg:items-center lg:justify-center lg:flex">
                <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                    <div class="mb-8 lg:mb-16 md:mx-auto md:items-center md:justify-center md:flex md:flex-col">
                        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Why Choose Us</h2>
                        <p class="text-gray-500 sm:text-xl dark:text-gray-400">What We Offer</p>
                    </div>
                    <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
                        <div>
                            <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4.5V19a1 1 0 0 0 1 1h15M7 14l4-4 4 4 5-5m0 0h-3.207M20 9v3.207"/>
                                </svg>

                            </div>
                            <h3 class="mb-2 text-xl font-bold dark:text-white">Cutting-Edge AI Expertise</h3>
                            <p class="text-gray-500 dark:text-gray-400">At DigiMoxy Solutions, we stay at the forefront of AI technology, ensuring our clients benefit from the latest advancements. Our team of experts continuously innovates to provide state-of-the-art AI capabilities. #digimoxysolutions #ai #machinelearning #algorithms</p>
                        </div>
                    <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 14h-2.722L11 20.278a5.511 5.511 0 0 1-.9.722H20a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1ZM9 3H4a1 1 0 0 0-1 1v13.5a3.5 3.5 0 1 0 7 0V4a1 1 0 0 0-1-1ZM6.5 18.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM19.132 7.9 15.6 4.368a1 1 0 0 0-1.414 0L12 6.55v9.9l7.132-7.132a1 1 0 0 0 0-1.418Z"/>
                </svg>

              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Seamless Integrations</h3>
              <p class="text-gray-500 dark:text-gray-400">We specialize in seamlessly connecting different systems, enabling enhanced data exchange and streamlined processes. Our integration solutions ensure that your business operations run smoothly and efficiently. #digital #automationsystems #automationsolutions #webdevelopment</p>
          </div>
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 0 0-2 2v4m5-6h8M8 7V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m0 0h3a2 2 0 0 1 2 2v4m0 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-6m18 0s-4 2-9 2-9-2-9-2m9-2h.01"/>
            </svg>
                   
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Comprehensive AI Solutions</h3>
              <p class="text-gray-500 dark:text-gray-400">From AI integrations to chatbot development and adaptive applications, we offer a comprehensive range of AI services tailored to meet your specific business needs. #digitalmarketing #marketingstrategy #marketingagency #webdesign #websitedesign #website</p>
          </div>
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M13 3a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0V3ZM6.343 4.929A1 1 0 0 0 4.93 6.343l1.414 1.414a1 1 0 0 0 1.414-1.414L6.343 4.929Zm12.728 1.414a1 1 0 0 0-1.414-1.414l-1.414 1.414a1 1 0 0 0 1.414 1.414l1.414-1.414ZM12 7a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm-9 4a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H3Zm16 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2ZM7.757 17.657a1 1 0 1 0-1.414-1.414l-1.414 1.414a1 1 0 1 0 1.414 1.414l1.414-1.414Zm9.9-1.414a1 1 0 0 0-1.414 1.414l1.414 1.414a1 1 0 0 0 1.414-1.414l-1.414-1.414ZM13 19a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0v-2Z" clip-rule="evenodd"/>
            </svg>

              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Enhanced Customer Interactions</h3>
              <p class="text-gray-500 dark:text-gray-400">Our AI-powered solutions help you deliver exceptional customer experiences. With intelligent chatbots and personalized recommendations, you can engage your customers more effectively and build lasting relationships. #virtualassistant #virtualassistance #virtualassistantservices #marketing #searchenginemarketing #amazonserviceprovider</p>
          </div>
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.891 15.107 15.11 8.89m-5.183-.52h.01m3.089 7.254h.01M14.08 3.902a2.849 2.849 0 0 0 2.176.902 2.845 2.845 0 0 1 2.94 2.94 2.849 2.849 0 0 0 .901 2.176 2.847 2.847 0 0 1 0 4.16 2.848 2.848 0 0 0-.901 2.175 2.843 2.843 0 0 1-2.94 2.94 2.848 2.848 0 0 0-2.176.902 2.847 2.847 0 0 1-4.16 0 2.85 2.85 0 0 0-2.176-.902 2.845 2.845 0 0 1-2.94-2.94 2.848 2.848 0 0 0-.901-2.176 2.848 2.848 0 0 1 0-4.16 2.849 2.849 0 0 0 .901-2.176 2.845 2.845 0 0 1 2.941-2.94 2.849 2.849 0 0 0 2.176-.901 2.847 2.847 0 0 1 4.159 0Z"/>
            </svg>

              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Competitive Edge</h3>
              <p class="text-gray-500 dark:text-gray-400">By partnering with DigiMoxy Solutions, you unlock the full potential of AI, gaining a competitive edge in your industry. Our innovative solutions drive growth, efficiency, and customer satisfaction. #digitalmarketing #marketingdigital #growth #data #future</p>
          </div>
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="m7.4 3.736 3.43 3.429A5.046 5.046 0 0 1 12.133 7c.356.01.71.06 1.056.147l3.41-3.412a2.32 2.32 0 0 1 .451-.344A9.89 9.89 0 0 0 12.268 2a10.022 10.022 0 0 0-5.322 1.392c.165.095.318.211.454.344Zm11.451 1.54-.127-.127a.5.5 0 0 0-.706 0l-2.932 2.932c.03.023.05.054.078.077.237.194.454.41.651.645.033.038.077.067.11.107l2.926-2.927a.5.5 0 0 0 0-.707Zm-2.931 9.81c-.025.03-.058.052-.082.082a4.97 4.97 0 0 1-.633.639c-.04.036-.072.083-.115.117l2.927 2.927a.5.5 0 0 0 .707 0l.127-.127a.5.5 0 0 0 0-.707l-2.932-2.931Zm-1.443-4.763a3.037 3.037 0 0 0-1.383-1.1l-.012-.007a2.956 2.956 0 0 0-1-.213H12a2.964 2.964 0 0 0-2.122.893c-.285.29-.509.634-.657 1.013l-.009.016a2.96 2.96 0 0 0-.21 1 2.99 2.99 0 0 0 .488 1.716l.032.04a3.04 3.04 0 0 0 1.384 1.1l.012.007c.319.129.657.2 1 .213.393.015.784-.05 1.15-.192.012-.005.021-.013.033-.018a3.01 3.01 0 0 0 1.676-1.7v-.007a2.89 2.89 0 0 0 0-2.207 2.868 2.868 0 0 0-.27-.515c-.007-.012-.02-.025-.03-.039Zm6.137-3.373a2.53 2.53 0 0 1-.349.447l-3.426 3.426c.112.428.166.869.161 1.311a4.954 4.954 0 0 1-.148 1.054l3.413 3.412c.133.134.249.283.347.444A9.88 9.88 0 0 0 22 12.269a9.913 9.913 0 0 0-1.386-5.319ZM16.6 20.264l-3.42-3.421c-.386.1-.782.152-1.18.157h-.135c-.356-.01-.71-.06-1.056-.147L7.4 20.265a2.503 2.503 0 0 1-.444.347A9.884 9.884 0 0 0 11.732 22H12a9.9 9.9 0 0 0 5.044-1.388 2.515 2.515 0 0 1-.444-.348ZM3.735 16.6l3.426-3.426a4.608 4.608 0 0 1-.013-2.367L3.735 7.4a2.508 2.508 0 0 1-.349-.447 9.889 9.889 0 0 0 0 10.1 2.48 2.48 0 0 1 .35-.453Zm5.101-.758a4.959 4.959 0 0 1-.65-.645c-.034-.038-.078-.067-.11-.107L5.15 18.017a.5.5 0 0 0 0 .707l.127.127a.5.5 0 0 0 .706 0l2.932-2.933c-.029-.018-.049-.053-.078-.076Zm-.755-6.928c.03-.037.07-.063.1-.1.183-.22.383-.423.6-.609.046-.04.081-.092.128-.13L5.983 5.149a.5.5 0 0 0-.707 0l-.127.127a.5.5 0 0 0 0 .707l2.932 2.931Z"/>
                </svg>

              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Commitment to Excellence</h3>
              <p class="text-gray-500 dark:text-gray-400">At DigiMoxy Solutions, we are committed to excellence in everything we do. Our goal is to help your business thrive through the power of AI. #digimoxysolutions #letsconnect #letsconnections #letsgrowmorecommunity #letsgrowtogether</p>
          </div>
      </div>
  </div>
</section>
            {/* </div> */}
        </div>
        <Testimonies />
        </>
     );
}
 
export default Homepage;