const Testominies = () => {
    return ( 
        <>
            <section class="bg-white dark:bg-gray-900">
            <div class="mb-8 lg:mb-16 md:mx-auto md:items-center md:justify-center md:flex md:flex-col">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Testimonies</h2>
                <p className="text-gray-500 sm:text-xl dark:text-gray-400">Successfull Stories</p>
            </div>
  <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
      <figure class="max-w-screen-md mx-auto">
          <svg class="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
          </svg> 
          <blockquote>
              <p class="text-2xl font-medium text-gray-900 dark:text-white">"DigiMoxy Solutions is just awesome!
              "It contains tons of predesigned components and pages starting from login screens to complex dashboards. Perfect choice for your next SaaS application.""</p>
          </blockquote>
          <figcaption class="flex items-center justify-center mt-6 space-x-3">
              <img class="w-6 h-6 rounded-full" src="leopard.jpg" alt="profile picture" />
              <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                  <div class="pr-3 font-medium text-gray-900 dark:text-white">Kubra Lohar</div>
                  <div class="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">Member, Digimoxy</div>
              </div>
          </figcaption>
      </figure>
  </div>
</section>
        </>
     );
}
 
export default Testominies;