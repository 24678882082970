const About = () => {
    return ( 
        <>
        <section class="bg-white dark:bg-gray-900  pt-[50px]">
            <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white md:text-center lg:text-start text-center">Digimoxy Solutions</h2>
                    <p class="mb-4 text-justify dark:text-gray-300">At DigiMoxy Solutions, we are your go-to #marketingagency, specializing in #webdesign, #webdevelopment, and #website solutions. As strategists, designers, and developers, we’re adept at blending innovation with practical problem-solving. Our team is small enough to be agile but experienced enough to handle the comprehensive scope required for #digitalmarketing success .</p>
                    <p className="dark:text-gray-300">We are strategists, designers and developers. Innovators and problem solvers. Small enough to be simple and quick.</p>
                </div>
                <div class="grid grid-cols-2 gap-4 mt-8">
                    <img class="w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png" alt="office content 1" />
                    <img class="mt-4 w-full lg:mt-10 rounded-lg" src="about1.jpg" alt="office content 2" />
                </div>
            </div>
        </section>
        <section class="bg-white dark:bg-gray-900">
    <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <img class="w-full dark:hidden rounded-md" src="/mission1.jpg" alt="dashboard image1" />
        <img class="w-full hidden dark:block rounded-md" src="/light_landing.jpg" alt="dashboard image2" />
        <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Our Mission</h2>
            <p class="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-300">Our mission is to empower businesses with innovative #AI solutions that drive growth, efficiency, and customer satisfaction. We integrate advanced AI technologies to enhance #searchengineoptimisation, #digitalmarketing, and #amazonfba strategies. From intelligent automation to personalized user experiences, our commitment is to provide state-of-the-art capabilities that give our clients a competitive edge.</p>
            <a href="t" class="inline-flex items-center text-gray-200 bg-gradient-to-r from-purple-500 to-cyan-500 hover:bg-gradient-to-r hover:from-cyan-500 hover:to-purple-500 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900">
                Learn More
                <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </a>
        </div>
    </div>
</section>
<section class="bg-white dark:bg-gray-900">
    <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div class="max-w-screen-md">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Our Vision</h2>
            <p class="mb-8 font-light text-gray-500 sm:text-xl dark:text-gray-300">We aim to be a global leader in #AI solutions, continuously advancing what’s possible with artificial intelligence. Our vision is to seamlessly integrate AI into business operations, enhancing productivity and improving customer interactions. By staying at the forefront of #marketingstrategy and offering premium #virtualassistant services, we help businesses thrive in a digital-first world. At DigiMoxy Solutions, we envision a future where AI drives smarter, more efficient business practices and contributes to a more connected society.</p>
            <div class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                <a href="rr" class="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center text-gray-200 bg-gradient-to-r from-purple-500 to-cyan-500 hover:bg-gradient-to-r hover:from-cyan-500 hover:to-purple-500 rounded-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                    Learn More
                </a>
                {/* <a href="#" class="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                    <svg class="mr-2 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path></svg>
                    View more
                </a>   */}
            </div>
        </div>
    </div>
</section>
        </>
     );
}
 
export default About;