const Services = () => {
    return ( 
        <>
        <section class="bg-white dark:bg-gray-900 pt-[70px]">
    <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div class="mr-auto place-self-center lg:col-span-7">
            <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">Technology Leverage from Us</h1>
            <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Partner with DigiMoxy Solutions to leverage the power of AI and transform your business. Our innovative services are designed to help you achieve operational excellence, enhance customer experiences, and stay ahead in the competitive market.</p>
            <a href="e" class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-gray-200 bg-gradient-to-r from-purple-500 to-cyan-500 hover:bg-gradient-to-r hover:from-cyan-500 hover:to-purple-500 rounded-lg focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                Get started
                <svg class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </a>
            <a href="r" class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                Join Us
            </a> 
        </div>
        <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/phone-mockup.png" alt="mockup" />
            {/* <img src="/engage.png" alt="mockup" /> */}
        </div>                
    </div>
</section>
<section class="bg-white dark:bg-gray-900">
  <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div class="mx-auto items-center justify-center flex flex-col py-10">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Our Services</h2>
          <p class="text-gray-500 sm:text-xl dark:text-gray-400 lg:w-[80%] md:text-center">At DigiMoxy Solutions, we offer a comprehensive range of AI-driven services designed to meet the diverse needs of modern businesses. Our solutions are tailored to enhance operational efficiency, improve customer interactions, and drive growth. Here’s a look at what we offer:</p>
      </div>
      <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
          <div className="dark:shadow-sm dark:hover:shadow-lg dark:hover:shadow-white dark:hover:cursor-pointer dark:rounded-md dark:shadow-white p-4">
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img src="/accessibility.png" className="w-5" alt="icon"/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">AI Integrations</h3>
              <ul class="text-gray-500 dark:text-gray-400 flex flex-col">
                <li>- Seamless integration of AI technologies into existing systems</li>
                <li>- Enhanced data exchange and streamlined processes</li>
                <li>- Custom solutions to fit specific business needs</li>
            </ul>
          </div>
          <div className="dark:shadow-sm dark:hover:shadow-lg dark:hover:shadow-white dark:hover:cursor-pointer dark:rounded-md dark:shadow-white p-4">
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img src="/connect.png" className="w-5" alt="icon"/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">AI Bot Creation</h3>
              <ul class="text-gray-500 dark:text-gray-400 flex flex-col">
                <li>- Development of intelligent chatbots and virtual assistants</li>
                <li>- Natural language processing for personalized customer interactions</li>
                <li>- 24/7 customer support and engagement</li>
            </ul>
          </div>
          <div className="dark:shadow-sm dark:hover:shadow-lg dark:hover:shadow-white dark:hover:cursor-pointer dark:rounded-md dark:shadow-white p-4">
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img src="/govern.png" className="w-5" alt="icon"/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white"> AI Web and App Services</h3>
              <ul class="text-gray-500 dark:text-gray-400 flex flex-col">
                <li>- AI-driven web and mobile application development</li>
                <li>- Machine learning algorithms for personalized user experiences</li>
                <li>- Adaptive applications that respond to user behavior</li>
            </ul>
          </div>
          <div className="dark:shadow-sm dark:hover:shadow-lg dark:hover:shadow-white dark:hover:cursor-pointer dark:rounded-md dark:shadow-white p-4">
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img src="/transparency.png" className="w-5" alt="icon"/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Data Analytics and Insights</h3>
              <ul class="text-gray-500 dark:text-gray-400 flex flex-col">
                <li>- Advanced data analysis to uncover actionable insights</li>
                <li>- Predictive analytics for informed decision-making</li>
                <li>- Customizable dashboards and reporting tools</li>
            </ul>
          </div>
          <div className="dark:shadow-sm dark:hover:shadow-lg dark:hover:shadow-white dark:hover:cursor-pointer dark:rounded-md dark:shadow-white p-4">
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img src="/innovation.png" className="w-5" alt="icon"/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Automation Solutions</h3>
              <ul class="text-gray-500 dark:text-gray-400 flex flex-col">
                <li>- Intelligent automation of repetitive tasks</li>
                <li>- Workflow optimization to enhance productivity</li>
                <li>- Integration of robotic process automation (RPA)</li>
            </ul>
          </div>
          <div className="dark:shadow-sm dark:hover:shadow-lg dark:hover:shadow-white dark:hover:cursor-pointer dark:rounded-md dark:shadow-white p-4">
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img src="/slide1.png" className="w-5" alt="icon"/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">AI Consulting and Strategy</h3>
              <ul class="text-gray-500 dark:text-gray-400 flex flex-col">
                <li>- Expert consultation to identify AI opportunities</li>
                <li>- Strategic planning and implementation of AI initiatives</li>
                <li>- Ongoing support and optimization of AI projects</li>
            </ul>
          </div>
          <div className="dark:shadow-sm dark:hover:shadow-lg dark:hover:shadow-white dark:hover:cursor-pointer dark:rounded-md dark:shadow-white p-4">
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img src="/slide2.png" className="w-5" alt="icon"/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Operations</h3>
              <ul class="text-gray-500 dark:text-gray-400 flex flex-col">
                <li>- Seamless integration of AI technologies into existing systems</li>
                <li>- Enhanced data exchange and streamlined processes</li>
                <li>- Custom solutions to fit specific business needs</li>
            </ul>
          </div>
          <div className="dark:shadow-sm dark:hover:shadow-lg dark:hover:shadow-white dark:hover:cursor-pointer dark:rounded-md dark:shadow-white p-4">
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img src="/slide3.png" className="w-5" alt="icon"/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white"> Custom AI Solutions</h3>
              <ul class="text-gray-500 dark:text-gray-400 flex flex-col">
                <li>- Tailored AI solutions to address unique business challenges</li>
                <li>- Development of proprietary AI models and algorithms</li>
                <li>- Scalable solutions to grow with your business</li>
            </ul>
          </div>
          <div className="dark:shadow-sm dark:hover:shadow-lg dark:hover:shadow-white dark:hover:cursor-pointer dark:rounded-md dark:shadow-white p-4">
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img src="/slide4.png" className="w-5" alt="icon"/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">AI Training and Support</h3>
              <ul class="text-gray-500 dark:text-gray-400 flex flex-col">
                <li>- Comprehensive training programs for your team</li>
                <li>- Ongoing technical support and maintenance</li>
                <li>- Resources to ensure successful AI adoption</li>
            </ul>
          </div>
          <div className="dark:shadow-sm dark:hover:shadow-lg dark:hover:shadow-white dark:hover:cursor-pointer dark:rounded-md dark:shadow-white p-4"> 
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img src="/slide5.png" className="w-5" alt="icon"/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Natural Language Processing (NLP)</h3>
              <ul class="text-gray-500 dark:text-gray-400 flex flex-col">
                <li>- NLP solutions for text analysis and sentiment detection</li>
                <li>- Automated content generation and language translation</li>
                <li>- Enhanced search functionality and information retrieval</li>
            </ul>
          </div>
          <div className="dark:shadow-sm dark:hover:shadow-lg dark:hover:shadow-white dark:hover:cursor-pointer dark:rounded-md dark:shadow-white p-4">
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img src="/img5.png" className="w-5" alt="icon"/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Computer Vision</h3>
              <ul class="text-gray-500 dark:text-gray-400 flex flex-col">
                <li>- Image and video analysis for various applications</li>
                <li>- Object detection, facial recognition, and image classification</li>
                <li>- Integration with security, retail, and manufacturing systems</li>
            </ul>
          </div>
      </div>
  </div>
</section>
        </>
     );
}
 
export default Services;