// import { initFlowbite } from "flowbite";
import React from 'react';
import { Route, Routes } from 'react-router-dom';


import Homepage from "./Pages/Homepage";
import About from './Pages/About';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Services from './Pages/Services';
import Contact from './Pages/Contact';

function App() {
  return (
    <>
    <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    <Footer />
    </>
  );
}

export default App;
